<template>
  <TheAppShell
    v-if="!loadingCustomizations"
    :navigation="navigation"
    :is-anonymous-access="RoleGuards.isAnonLoggedIn()"
    :user="RoleGuards.isAnonLoggedIn() ? 'Anonymous' : userName"
    :image-src="userImage"
    :allow-dark-mode="config.allowDarkMode"
    :show-navigation-level2-bar="showLevel2Bar"
    :lvl1-bar-open-state-key="localStorageKey"
    @lvl1-bar-open="lvl1BarOpenHandler"
    @logout="logout"
    @support="support"
    @settings="settings"
  >
    <template v-if="logo.length > 0" #logo>
      <img :src="logo" class="object-contain max-h-full max-w-full" alt="" />
    </template>

    <template v-if="logo.length > 0 || logoSmall.length > 0" #logoSmall>
      <img
        v-if="logoSmall !== ''"
        class="object-contain max-h-full max-w-full"
        :src="logoSmall"
        alt=""
      />
      <img v-else class="object-contain max-h-full max-w-full" :src="logo" alt="" />
    </template>

    <template v-if="level2BarCompId" #NavigationMobileLevel2Bar>
      <ApiSidebarController
        v-if="level2BarType === Level2BarEnum.API"
        name="ApiSidebarController"
        :api-id="level2BarCompId"
        :hide-bar-by-small-resolution="false"
      />

      <ApplicationSidebarController
        v-if="level2BarType === Level2BarEnum.APPLICATION"
        name="ApplicationSidebarController"
        :application-id="level2BarCompId"
        :hide-bar-by-small-resolution="false"
      />

      <ApiProductSidebarController
        v-if="level2BarType === Level2BarEnum.API_PRODUCT"
        name="ApiProductSidebarController"
        :api-product-id="level2BarCompId"
        :hide-bar-by-small-resolution="false"
      />
    </template>

    <template #NavigationMobileAboveUserWithCloseMenu>
      <NavigationAboveUser v-if="showLevel2Bar" :lvl1-bar-open="false" />
      <NavigationAboveUser v-else :lvl1-bar-open="lvl1BarOpen" />
    </template>

    <template #NavigationMobileAboveUser>
      <ObsidianAppTutorial
        v-if="config.allowTutorial"
        :lvl1-bar-open="showLevel2Bar ? false : lvl1BarOpen"
        :mode="showLevel2Bar ? TutorialMode.MOBILE_WITH_LVL2 : TutorialMode.MOBILE"
        :dev-portal-name="properties.devPortalName"
      />
    </template>

    <template #NavigationDesktopAboveUser>
      <NavigationAboveUser :lvl1-bar-open="lvl1BarOpen" />
      <ObsidianAppTutorial
        v-if="config.allowTutorial"
        :lvl1-bar-open="lvl1BarOpen"
        :mode="TutorialMode.DESKTOP"
        :dev-portal-name="properties.devPortalName"
      />
    </template>
  </TheAppShell>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUpdated, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { TheAppShell } from '@apiida/vue-components';
import { TutorialMode } from '@/types/enums/TutorialMode';
import AuthService from '../../services/app/AuthService';
import mainStore from '../../stores/MainStore';
import UserService from '../../services/users/UserService';
import config from '../../config';
import RoleGuards from '../../routers/guards/RoleGuards';
import { Level2BarEnum } from '@/types/enums/Level2BarEnum';
import ApiSidebarController from '../api/ApiSidebarController.vue';
import ApplicationSidebarController from '../applications/ApplicationSidebarController.vue';
import ApiProductSidebarController from '../apiProducts/ApiProductSidebarController.vue';
import ObService from '../../services/app/ObService';
import PortalProperties from '../../types/app/PortalProperties';
import NavigationAboveUser from './NavigationAboveUser.vue';
import CmsService from '../../services/cms/CmsService';
import { CmsMenuItemType } from '@/types/enums/CmsMenuItemType';
import openNewTap from '../../helper/OpenLinkHelper';
import ObsidianAppTutorial from './ObsidianAppTutorial.vue';

const baseNavigation = [
  { name: 'Home', route: 'WelcomeView', icon: 'Home' },
  { name: 'APIs', route: 'APIs', icon: 'API' },
  { name: 'API Products', route: 'ApiProducts', icon: 'Types' },
];

export default defineComponent({
  name: 'ObsidianAppShell',
  components: {
    TheAppShell,
    ObsidianAppTutorial,
    NavigationAboveUser,
    ApiProductSidebarController,
    ApplicationSidebarController,
    ApiSidebarController,
  },
  emits: ['user-logged-out'],
  setup(_, { emit }) {
    const localStorageKey = 'DevPortalLvl1BarState';
    const lvl1BarOpen = ref<boolean>(true);

    const store = mainStore();
    const router = useRouter();
    const route = useRoute();
    const routeRef = ref(useRoute());

    const properties = ref<PortalProperties>({
      logoFile: '',
      logoFileSmall: '',
      logoFileIco: '',
      devPortalName: '',
    } as PortalProperties);
    const logo = ref('');
    const logoSmall = ref('');
    const loadingCustomizations = ref(true);
    const lastHighlightMenuItem = ref('');

    const navigation = ref<
      {
        name: string;
        route: string;
        icon: string;
      }[]
    >([]);

    const userName = ref<string>('');
    const userImage = ref('');

    const level2BarCompId = ref<number>();
    const level2BarType = ref<Level2BarEnum>();
    const showLevel2Bar = ref<boolean>(false);

    function level2BarHandler() {
      showLevel2Bar.value = false;
      if (routeRef.value.path.includes(Level2BarEnum.API)) {
        level2BarType.value = Level2BarEnum.API;
        showLevel2Bar.value = true;
        level2BarCompId.value = +routeRef.value.params.apiId;
      }

      if (routeRef.value.path.includes(Level2BarEnum.APPLICATION)) {
        level2BarType.value = Level2BarEnum.APPLICATION;
        showLevel2Bar.value = true;
        level2BarCompId.value = +routeRef.value.params.applicationId;
      }

      if (routeRef.value.path.includes(Level2BarEnum.API_PRODUCT)) {
        level2BarType.value = Level2BarEnum.API_PRODUCT;
        showLevel2Bar.value = true;
        level2BarCompId.value = +routeRef.value.params.apiProductId;
      }
    }

    async function loadCmsMenu() {
      const cmsMenu = await CmsService.getMenu();

      for (let i = 0; i < cmsMenu.length; i += 1) {
        const iconTxt = cmsMenu[i].iconName;

        if (cmsMenu[i].type === CmsMenuItemType.PAGE) {
          router.addRoute({
            path: `/${cmsMenu[i].text}`,
            name: `${cmsMenu[i].text}`,
            props: { cmsPageName: `${cmsMenu[i].pageName}` },
            component: () => import('../../views/CmsPageView.vue'),
          });

          navigation.value.push({
            name: `${cmsMenu[i].text}`,
            route: `${cmsMenu[i].text}`,
            icon: iconTxt,
          });
        } else {
          router.addRoute({
            path: `/${cmsMenu[i].text}`,
            name: `${cmsMenu[i].text}`,
            beforeEnter(to, from, next) {
              openNewTap(`${cmsMenu[i].url}`);
              next(false);
            },
            component: { template: `` },
          });
          navigation.value.push({
            name: `${cmsMenu[i].text}`,
            route: `${cmsMenu[i].text}`,
            icon: iconTxt,
          });
        }
      }
    }

    async function loadNavigation() {
      navigation.value = [...baseNavigation];

      if (!RoleGuards.isAnonLoggedIn()) {
        navigation.value.push({
          name: 'Applications',
          route: 'Applications',
          icon: 'Players',
        });
        if (RoleGuards.isAdminOrGlobalAdmin()) {
          navigation.value.push({ name: 'Users', route: 'Users', icon: 'User' });
        }
      }

      await loadCmsMenu();
    }

    async function setCustomizations() {
      properties.value = await ObService.loadProperties();

      // The FavIcon is set via config.ts, main.ts and index.html
      if (properties.value.logoFile && properties.value.logoFile.length > 0) {
        await ObService.getLogo(properties.value.logoFile, (base64Image: string) => {
          logo.value = base64Image;
          loadingCustomizations.value = false;
        });
      } else {
        loadingCustomizations.value = false;
      }

      if (properties.value.logoFileSmall && properties.value.logoFileSmall.length > 0) {
        await ObService.getLogo(properties.value.logoFileSmall, (base64Image: string) => {
          logoSmall.value = base64Image;
        });
      }
    }

    async function logout() {
      const resultLogout = await AuthService.logout();

      // When the Boomi Platform is connected, we redirect to the provider's logout page
      if (typeof resultLogout === 'string') {
        console.log('Redircet to Boomi Identity Provider (logout) ' + resultLogout);
        window.location.assign(resultLogout);
      } else {
        emit('user-logged-out');
      }
    }

    function support() {
      openNewTap(config.supportUrl);
    }

    function settings() {
      router.push({ name: 'AccountSettings', params: { id: store.loggedInUser.id } });
    }

    onMounted(async () => {
      setCustomizations();
      loadNavigation();
      userName.value = `${store.loggedInUser.firstName} ${store.loggedInUser.lastName}`;
      UserService.getCurrentImage(store.getLoggedInUser.id, false, (base64Image: string) => {
        userImage.value = base64Image;
      });
    });

    function manageLvl1ItemHighlighting() {
      let currentRouteName = router.currentRoute.value.path;
      currentRouteName = currentRouteName.replace('/', '');
      if (currentRouteName.length > 0) {
        if (lastHighlightMenuItem.value !== currentRouteName) {
          const selectedMenuItem = document.getElementById(
            `;
        id_navigationDesktop_navigation_$;
        {
          lastHighlightMenuItem.value;
        }
        `,
          );
          if (selectedMenuItem) selectedMenuItem.classList.remove('router-link-active');
        } else {
          const selectedMenuItem = document.getElementById(
            `;
        id_navigationDesktop_navigation_$;
        {
          currentRouteName;
        }
        `,
          );
          if (selectedMenuItem) selectedMenuItem.classList.add('router-link-active');
        }
        lastHighlightMenuItem.value = currentRouteName;
      }
    }

    onUpdated(() => {
      manageLvl1ItemHighlighting();
    });

    function lvl1BarOpenHandler(flag: boolean) {
      lvl1BarOpen.value = flag;
    }

    watch(
      () => route.name,
      () => {
        level2BarHandler();
      },
    );

    return {
      config,
      RoleGuards,
      TutorialMode,
      localStorageKey,
      lvl1BarOpen,
      lvl1BarOpenHandler,
      Level2BarEnum,
      level2BarType,
      level2BarCompId,
      showLevel2Bar,
      userName,
      navigation,
      userImage,
      properties,
      logo,
      logoSmall,
      logout,
      support,
      settings,
      loadingCustomizations,
    };
  },
});
</script>
