<template>
  <div>
    <nav
      v-if="isGlobalAdmin"
      aria-label="Sidebar"
      :class="[lvl1BarOpen ? 'items-left pr-4' : 'items-center']"
      class="flex flex-col pt-2"
    >
      <div
        :class="[
          'menu-link flex items-center p-2 rounded-lg cursor-pointer',
          store.cmsGlobalEditMode ? 'router-link-active' : '',
        ]"
        @click="store.cmsGlobalEditMode = !store.cmsGlobalEditMode"
      >
        <BaseIcon
          v-if="store.cmsGlobalEditMode"
          name="Create"
          label="Edit Mode"
          icon-class="text-base"
          aria-hidden="true"
        />
        <BaseIcon v-else name="Edit" label="Edit Mode" icon-class="text-base" aria-hidden="true" />
        <span v-if="lvl1BarOpen" class="pl-2">Edit Mode</span>
      </div>
    </nav>
  </div>
</template>

<script setup lang="ts">
import { onMounted, onUpdated, ref } from 'vue';
import RoleGuards from '../../routers/guards/RoleGuards';
import mainStore from '../../stores/MainStore';

defineProps({
  lvl1BarOpen: {
    type: Boolean,
    required: true,
  },
});

const isGlobalAdmin = ref(false);
const store = mainStore();

async function loadRole() {
  isGlobalAdmin.value = RoleGuards.isGlobalAdmin();
}

onMounted(async () => {
  await loadRole();
});

onUpdated(async () => {
  await loadRole();
});
</script>
