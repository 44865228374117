<template>
  <nav
    aria-label="Sidebar"
    :class="[lvl1BarOpen ? 'items-left pr-4' : 'items-center']"
    class="pb-6 flex flex-col pt-2"
  >
    <div
      class="menu-link flex items-center p-2 rounded-lg text-indigo-200 hover:bg-exo-color-background-selected-hover hover:text-primary-button-text hover:cursor-pointer"
      @click="showTutorial = true"
    >
      <BaseIcon name="Help" label="Open Tutorial" icon-class="text-base" aria-hidden="true" />
      <span v-if="lvl1BarOpen" class="pl-2">Tutorial</span>
    </div>
  </nav>

  <span v-if="tutorialData && (showTutorial || store.getFirstLoginFlag)">
    <Vue3Tutorial
      v-if="mode === TutorialMode.DESKTOP"
      class="max-lg:hidden vue3-tutorial_desktop"
      :tutorial="tutorialData"
      :open="showTutorial || store.getFirstLoginFlag"
      @stop="closeDialog"
      @start="adjustTutorialHtml"
      @change-step="adjustTutorialHtml"
    />

    <Vue3Tutorial
      v-else
      class="lg:hidden vue3-tutorial_mobile"
      :tutorial="tutorialData"
      :open="showTutorial || store.getFirstLoginFlag"
      @stop="closeDialog"
      @start="adjustTutorialHtml"
      @change-step="adjustTutorialHtml"
    />
  </span>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted, PropType, ref } from 'vue';
import { BaseIcon } from '@apiida/vue-components';
import Vue3Tutorial, { Tutorial } from 'vue3-tutorial';
/* import BoomiLogo from '../../assets/Boomi_logo.svg';
import BoomiLogoDarkmode from '../../assets/Boomi_logo_reversed.svg'; */
import RoleGuards from '../../routers/guards/RoleGuards';
import mainStore from '../../stores/MainStore';
import { TutorialMode } from '@/types/enums/TutorialMode';

export default defineComponent({
  components: { BaseIcon, Vue3Tutorial },
  props: {
    lvl1BarOpen: {
      type: Boolean,
      required: true,
    },
    mode: {
      type: String as PropType<TutorialMode>,
      required: true,
    },
    devPortalName: {
      type: String,
      default: 'Developer Portal',
    },
  },
  setup(props) {
    const store = mainStore();
    const showTutorial = ref(false);
    const isGlobalAdmin = ref(false);
    const isAdmin = ref(false);

    const tutorialData = ref<Tutorial>();

    function checkDarkMode() {
      return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    }

    const isDarkMode = ref(checkDarkMode());

    const handleColorSchemeChange = (e: MediaQueryListEvent) => {
      isDarkMode.value = e.matches;
    };

    let colorSchemeQuery: MediaQueryList | null = null;

    function getTutorialSteps(tutorialMode: TutorialMode): Tutorial | undefined {
      if (isGlobalAdmin.value || isAdmin.value) {
        return {
          name: 'short tutorial',
          steps: [
            {
              title: 'Welcome to the Developer Portal of the Boomi API Control Plane!',
              content:
                `This quick tutorial will help you understand the basic functionality and set you up for a successful use.` +
                `\n\n` +
                `For detailed information you can always take a look at the [documentation](https://help.boomi.com/docs/Atomsphere/API%20Management/Topics/cp-Introduction).`,
              options: { position: 'center' },
            },
            {
              target: `[data-test-id="EditMode-div"]`,
              title: 'Customize it!',
              content: `The Developer Portal can be completely customized according to your wishes. Logos, and colors can be changed in the Admin Portal, under Configuration > Developer Portals. Texts can be modified directly here with this switch.`,
            },
            {
              target: `a[data-test-id="${tutorialMode}_APIs"]`,
              title: 'The APIs',
              content:
                'All APIs that are in an API product that is displayed here are also displayed here. They contain a general description, the Swagger documentation to try them out and a specific documentation and as well information about the versions can be found here. \n\n' +
                'A single API cannot be subscribed, only API products.',
            },
            {
              target: `a[data-test-id="${tutorialMode}_API Products"]`,
              title: 'API Products',
              content:
                'An API product can contain one or more APIs. To subscribe to it, the corresponding API product must be subscribed via an application. \n\n' +
                'In the detailed view of an API product, there is more information about, for example, visibility, deployments, plans, subscriptions and associated APIs.',
            },
            {
              target: `a[data-test-id="${tutorialMode}_Applications"]`,
              title: 'Handle Applications and Subscriptions',
              content:
                'All applications created by this user or applications of the associated organization are displayed here. They contain information about the subscriptions, which can also be changed here. \n\n' +
                'In addition, new subscriptions of an application to an API can be created and existing subscriptions including the API key are displayed.',
            },
          ],
          options: {
            scroll: false,
            position: 'right',
          },
        } as Tutorial;
      }

      return {
        name: 'short tutorial',
        steps: [
          {
            title: `Welcome to ${props.devPortalName}`,
            content: `This quick tutorial will help you understand the basic functionality and set you up for a successful use.`,
            options: { position: 'center' },
          },
          {
            target: `a[data-test-id="${tutorialMode}_APIs"]`,
            title: 'The APIs',
            content:
              'All available APIs are listed here. They include a general description, the Swagger documentation to try it out, specific documentation, information about the versions and in which API products it is included.',
          },
          {
            target: `a[data-test-id="${tutorialMode}_API Products"]`,
            title: 'API Products',
            content:
              'Here you can find the API products. The included APIs can be viewed here and to which condicions they can be subscribed.',
          },
          {
            target: `a[data-test-id="${tutorialMode}_Applications"]`,
            title: 'Handle Applications and Subscriptions',
            content:
              'With an application, API products can be subscribed and subscriptions can be managed.',
          },
          {
            title: 'Give it a try!',
            content: 'Create an application, choose a plan and subscribe to an API Product!',
            options: { position: 'center' },
          },
        ],
        options: {
          scroll: false,
          position: 'right',
        },
      } as Tutorial;
    }

    /*    function getHtmlLogoElement(id: string): HTMLImageElement {
      const elem: HTMLImageElement = document.createElement('img');
      elem.id = id;
      if (isDarkMode.value) {
        elem.src = BoomiLogoDarkmode;
      } else {
        elem.src = BoomiLogo;
      }

      elem.classList.add('pb-2');
      elem.classList.add('mb-4');
      elem.classList.add('w-28');

      return elem;
    } */

    function adjustTutorialHtml() {
      setTimeout(() => {
        // Change Steptext to Step x of y
        const stepDiv = document.querySelector(
          '.vue3-tutorial__step__header__status',
        ) as HTMLDivElement;
        let newStepText = stepDiv.innerText;
        newStepText = newStepText.charAt(0).toUpperCase() + newStepText.slice(1);
        newStepText = newStepText.replace('/', 'of');
        stepDiv.innerText = newStepText;

        // Open links in another tab.
        const links = document
          .querySelector('.vue3-tutorial__step__content')
          ?.getElementsByTagName('a');
        if (links)
          for (let i = 0; i < links.length; i += 1) {
            links[i].setAttribute('target', '_blank');
            links[i].setAttribute('rel', 'noopener');
          }

        // Add boomi Logo to Header
        /*        const imgIdDesktop = 'vue3-tutorial__step__header-aiida-logo_desktop';
        if (document.getElementById(imgIdDesktop) === null) {
          const header = document.querySelector(
            '.vue3-tutorial_desktop > .vue3-tutorial__window > .vue3-tutorial__step > .vue3-tutorial__step__header'
          );
          header?.parentNode?.insertBefore(getHtmlLogoElement(imgIdDesktop), header);
        }
        const imgIdMobile = 'vue3-tutorial__step__header-aiida-logo_mobile';
        if (document.getElementById(imgIdMobile) === null) {
          const header = document.querySelector(
            '.vue3-tutorial_mobile > .vue3-tutorial__window > .vue3-tutorial__step > .vue3-tutorial__step__header'
          );
          header?.parentNode?.insertBefore(getHtmlLogoElement(imgIdMobile), header);
        } */
      }, 1);
    }

    function closeDialog() {
      showTutorial.value = false;
      store.setFirstLoginFlag(false);
    }

    onMounted(async () => {
      isGlobalAdmin.value = RoleGuards.isGlobalAdmin();
      isAdmin.value = RoleGuards.isAdmin();
      tutorialData.value = getTutorialSteps(props.mode);
      colorSchemeQuery = window.matchMedia('(prefers-color-scheme: dark)');
      colorSchemeQuery.addListener(handleColorSchemeChange);
    });

    onUnmounted(() => {
      if (colorSchemeQuery) {
        colorSchemeQuery.removeListener(handleColorSchemeChange);
      }
    });

    return {
      tutorialData,
      adjustTutorialHtml,
      closeDialog,
      store,
      showTutorial,
    };
  },
  computed: {
    TutorialMode() {
      return TutorialMode;
    },
  },
});
</script>
